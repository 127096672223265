<template>
    <div>
        <div v-if="exist" class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-0">
            <div class="row justify-content-around bg-current px-2">
                <div class="card-body mb-3 pb-0"><h2 class="fw-400 font-md d-block text-white"><b>Edit Questionnaire Open</b></h2></div>
            </div>
            <div class="card-body p-2" v-if="!isLoad">
                <form @submit.prevent="patchQuestionnaire" class="row p-2">
                    <div class="col-12 form-group mb-3">
                        <label for="">Title</label>
                        <input type="text" v-model="questionnaire.title"  required placeholder="Enter your questionnaire title" class="form-control text-grey-900 font-xsss fw-600">                        
                    </div>
                    <div class="col-12 form-group mb-3">
                        <label for="">Description</label>
                        <textarea rows="1" v-model="questionnaire.description" required placeholder="Enter your questionnaire description" class="form-control text-grey-900 font-xsss fw-600"></textarea>
                    </div>
                    <div class="col-12 form-group text-right my-4">
                        <router-link :to="{name: 'ReflectionCoursesAdmin', params: {idCourse: paramsId}, hash: '#open'}" class="btn btn-outline-warning mr-4" value="Submit">Cancel</router-link>
                        <input type="Submit" class="btn btn-current" value="Submit">
                    </div>
                </form>
            </div>
            <div class="card-body py-2" v-else>
                <div class="row">
                    <div class="col-12 text-center py-3">
                        <div class="my-3">
                            <div class="spinner-grow text-warning mx-3" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <div class="spinner-grow text-warning mx-3" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <div class="spinner-grow text-warning mx-3" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <div class="spinner-grow text-warning mx-3" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <div class="spinner-grow text-warning mx-3" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <div class="spinner-grow text-warning mx-3" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-0">
            <div class="row justify-content-center">
                <div class="col-lg-6 col-md-8 text-center default-page">
                    <div class="card border-0 text-center d-block">
                        <img :src="'/images/404.png'" alt="icon" class="w300 mb-4 ml-auto mr-auto ">
                        <h1 class="fw-700 text-grey-900 display4-size display4-md-size">Page Not Found.</h1>
                        <p class="text-grey-500 font-xss">The page you're looking for isn't available. Try to search again or use the go to.</p>
                        <button @click="$router.go(-1)" class="p-3 border-0 w175 bg-current text-white d-inline-block text-center fw-600 font-xssss rounded-lg text-uppercase ls-3">Return Back</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'

export default {
    name: "AnouncementsCreate",
    data(){
        return {
            paramsId: this.$route.params.idCourse,
            idQuestionnaire: this.$route.params.idQuestionnaire,
            isLoad: true,
            exist: true,
            syllabus: [],
            unit: [],
            cycle: '',
            questionnaire: {
                id: '',
                course_id: '',
                title: '',
                description: '',
            },
        }
    },
    created(){
        this.getQuestionnaire()
    },
    methods:{
        async getQuestionnaire(){
            await axios.get(`${process.env.VUE_APP_URL_API}/admin/reflection/questionnaire/detail?slug=${this.paramsId}&id=${this.idQuestionnaire}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                var data = res.data.data
                this.questionnaire = {
                    id: data.id,
                    course_id: data.course_id,
                    title: data.title,
                    description: data.description,
                }
                this.isLoad = false
                
            }).catch(err => {
                if (err.response.status == 404) {
                    this.exist = false
                }
            })
        },
        async patchQuestionnaire() {
            var data = {
                _method: 'patch',
                id: this.idQuestionnaire,
                slug: this.paramsId,
                title: this.questionnaire.title,
                description: this.questionnaire.description,
            }
            await axios.post(`${process.env.VUE_APP_URL_API}/admin/reflection/questionnaire`, data, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                this.$swal({
                    toast: true,
                    title: 'Questionnaire - Open!',
                    text: res.data.message,
                    icon: 'success',
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                })
                this.$router.push({ name: 'ReflectionCoursesAdmin', params: {idCourse: this.paramsId}, hash: '#open' })
            }).catch(err => {
                if (err.response.status == 422) {
                    this.$swal({
                        toast: true,
                        title: 'Questionnaire - Open!',
                        text: err.response.data.message[0],
                        icon: 'error',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            })
        },

    }
}
</script>